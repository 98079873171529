import React, { Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import { Loader } from "@sizdevteam1/funjoiner-uikit";

interface IProps extends RouteProps {
  title?: string;
}

const PublicRoute: React.FC<IProps> = ({ title, ...rest }) => {
  React.useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return <Route {...rest} />;
};
export default PublicRoute;
