import apiService from "./apiService";
import { ICreditDTO, ICreditTypeDTO, IPaymentDTO } from ".";
import { IPagination, IPaginationParams } from "./common";
import { Cents, ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-shared/services";
import { TPaymentType } from "@sizdevteam1/funjoiner-web-shared/interfaces/orders";

// Regular Orders
export type TOrderStatus =
  | "incomplete"
  | "completed"
  | "awaiting_payment"
  | "cancelled";

interface IOrderItemBase {
  id: string;
  description: string;
  initial_price_per_item: number;
  discount: number;
  quantity: number;
  final_price: number;
}

export interface ICreditOrderItemDTO extends IOrderItemBase {
  type: "credit_item";
  credit_type: ICreditTypeDTO;
  credits_created: ICreditDTO[];
  is_package: boolean;
}
export interface IInlineOrderItemDTO extends IOrderItemBase {
  type: "inline_item";
}
export interface IRescheduleOrderItem extends IOrderItemBase {
  type: "reschedule_item";
  credit_id: number;
  new_program_id: number;
  new_session_id?: number;
}
export interface IUpgradeOrderItem extends IOrderItemBase {
  type: "upgrade_item";
  credit_id: number;
  from_credit_type_id: number;
  to_credit_type_id: number;
  final_price: Cents;
}

export type IOrderItemDTO =
  | ICreditOrderItemDTO
  | IInlineOrderItemDTO
  | IRescheduleOrderItem
  | IUpgradeOrderItem;

export interface IFeeInOrder {
  id: string;
  fee_name: string;
  fee_description: string;
  amount: number;
}

export type PeriodicPaymentPlanType =
  | "monthly_payments"
  | "biweekly_payments"
  | "weekly_payments";

export type TPaymentPlanType =
  | "half_now_half_later"
  | "minimum_deposit"
  | PeriodicPaymentPlanType;

export interface IOrderPaymentPlanDTO {
  id: string;
  order_id: number;
  payment_plan_id: string;
  installments: IOrderPaymentPlanInstallmentDTO[];
  setup_fee?: IPaymentPlanFeeDTO;
  late_payment_fee?: IPaymentPlanFeeDTO;
  paid_percentage: number;
  is_completed: boolean;
  type: TPaymentPlanType;
}

export interface IAvailableOrderPaymentPlan {
  payment_plan: IPaymentPlanDTO;
  installments: IOrderPaymentPlanInstallmentDTO[];
}

export interface IPaymentPlanFeeDTO {
  flat_amount: number;
  description: string;
}

export interface IPaymentPlanDTO {
  id: string;
  type: TPaymentPlanType;
  name: string;
  description: string;
  deadline_date: ISOString;
  activation_date: ISOString;
  applicable_to_location_ids: number[] | "all";
  applicable_to_program_type_ids: number[] | "all";
  setup_fee?: IPaymentPlanFeeDTO;
  late_payment_fee?: IPaymentPlanFeeDTO;
  minimum_deposit?: number;
  deposit_type?: "percentage" | "flat_amount";
  status: "active" | "inactive" | "archived";
  expires_when_days_before_deadline_left: number;
  is_expired: boolean;
  is_active: boolean;
}

export interface IPaymentPlanFeeDTO {
  flat_amount: number;
  description: string;
}
export interface IOrderPaymentPlanInstallmentDTO {
  id: string;
  ordinal_number: number;
  order_payment_plan_id: string;
  payment_id?: number;
  payment?: IPaymentDTO;
  amount: number;
  initial_amount: number;
  due_date: ISOString;
  own_fees: IFeeInOrder[];
  applied_order_fees: IFeeInOrder[];
  is_delinquent: boolean;
  is_missed: boolean;
  is_paid: boolean;
  automatic_charge_attempts: number;
}

export interface IOrderDTO {
  id: number;
  comment: string | null;
  description: string;
  customer_id: number;
  items: IOrderItemDTO[];
  status: TOrderStatus;
  discount: number;
  final_price: number;
  final_price_without_fees: number;
  payment: IPaymentDTO;
  created_at: ISOString;
  payment_plan: IOrderPaymentPlanDTO | null;
  promocode_id: string | null;
  promocode_discount: number;
  own_fees: IFeeInOrder[];
  installment_fees: IInstallmentFeesInOrderDTO[];
}

export interface IInstallmentFeesInOrderDTO extends IFeeInOrder {
  installment_id: string;
}
export type TAlternativePaymentSource =
  | "venmo"
  | "cash"
  | "wire_transfer"
  | "check"
  | "other";

export type ICalculatedOrderDTO = Omit<IOrderDTO, "id" | "payment">;

export interface ICreateInlineOrderItemDTO {
  product_description: string;
  price_per_item: number;
  quantity: number;
}
export interface ICreateCreditOrderItemDTO {
  quantity: number;
  credit_type_id: number;
  is_package: boolean;
}

export interface ICreateUpgradeOrderItemDTO {
  credit_id: number;
  to_credit_type_id: number;
}
export type TCreateOrderItemDTO =
  | ICreateInlineOrderItemDTO
  | ICreateCreditOrderItemDTO
  | ICreateUpgradeOrderItemDTO;

export interface ICalculateOrderDTO {
  items: TCreateOrderItemDTO[];
  promocode_id: string | undefined;
}

export interface IPlaceOrderDTO {
  items: TCreateOrderItemDTO[];
  payment_type: TPaymentType;
  promocode_id: string | undefined;
  new_customer_email?: string;
}

// Schedule and pay orders

export interface IScheduleAndPayOrderWithPaymentPLanDTO
  extends IScheduleAndPayOrderDTO {
  payment_plan: IOrderPaymentPlanDTO;
}

export interface ICreateScheduleAndPayOrderDTO {
  booking_data: TBookingDataItemDTO[];
  promocode_id: string | undefined;
  payment_plan_id: string | undefined;
  new_customer_email: string | undefined;
  payment_type: TPaymentType;
}

export interface ICalculateScheduleAndPayOrderDTO {
  booking_data: TBookingDataItemDTO[];
  skip_all_credits?: boolean;
  promocode_id: string | undefined;
  payment_plan_id: string | undefined;
}

type BookSessionDTO = {
  student_id: number;
  schedule_set_id: string;
  program_id: string;
  session_id: string;
};

type BookProgramDTO = {
  student_id: number;
  schedule_set_id: string;
  program_id: string;
};

export type TBookingDataItemDTO = BookSessionDTO | BookProgramDTO;

export type TBuyThenScheduleDTO = TBookingDataItemDTO & {
  credit_id: number;
};

export interface ISignUpSessionDTO {
  schedule_set_id: string;
  program_id: string;
  session_id: string;
  student_id: number;
  credit_id: number;
}
export interface ISignUpProgramDTO {
  schedule_set_id: string;
  program_id: string;
  student_id: number;
  credit_id: number;
}

export type TSignUpDTO = ISignUpSessionDTO | ISignUpProgramDTO;

export interface IScheduleAndPayOrderDTO {
  id: number;
  customer_id: number;
  created_at: ISOString;
  comment: string | null;
  credits_used: number[];
  discount: number;
  description: string;
  final_price: number;
  final_price_without_fees: number;
  initial_price: number;
  status: TOrderStatus;
  payment: IPaymentDTO;
  items: ICreditOrderItemDTO[];
  payment_plan: IOrderPaymentPlanDTO | null;
  promocode_id: string | null;
  promocode_discount: number;
  own_fees: IFeeInOrder[];
  installment_fees: IInstallmentFeesInOrderDTO[];
  scholarship_amount: number | null;
  sign_up: {
    buy_then_schedule: TBuyThenScheduleDTO[];
    schedule: TSignUpDTO[];
    created_attendance_ids: string[];
  };
}

export interface IScheduleAndPayOrderWithPaymentPlanDTO
  extends IScheduleAndPayOrderDTO {
  payment_plan: IOrderPaymentPlanDTO;
}

export const isScheduleAndPayOrder = (
  o: IScheduleAndPayOrderDTO | IOrderDTO
): o is IScheduleAndPayOrderDTO => "sign_up" in o && o.sign_up != null;

export type ICalculatedScheduleAndPayOrderDTO = Omit<
  IScheduleAndPayOrderDTO,
  "id" | "payment"
>;

export type ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO = {
  order: ICalculatedScheduleAndPayOrderDTO;
  available_payment_plans: IAvailableOrderPaymentPlan[];
};

export const getOrders = (
  params: IPaginationParams
): Promise<IPagination<IOrderDTO>> =>
  apiService.get("/customer_api/v2/orders/", {
    searchParams: params,
  });

export const calculate = (
  dto: ICalculateOrderDTO
): ICancelablePromise<ICalculatedOrderDTO> =>
  apiService.post("/customer_api/v2/orders/calculate", { data: dto });

export const place = (dto: IPlaceOrderDTO): Promise<IOrderDTO> =>
  apiService.post("/customer_api/v2/orders/place", {
    data: dto,
  });

export const calculateScheduleAndPayOrder = (
  data: ICalculateScheduleAndPayOrderDTO
): ICancelablePromise<ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO> =>
  apiService.post(
    `/customer_api/v2/orders/calculate_schedule_and_pay/extended`,
    {
      data,
    }
  );

export const placeScheduleAndPayOrder = (
  data: ICreateScheduleAndPayOrderDTO
): ICancelablePromise<IScheduleAndPayOrderDTO> =>
  apiService.post(`/customer_api/v2/orders/place_schedule_and_pay`, { data });

export const getOrderById = (
  id: number
): Promise<IOrderDTO | IScheduleAndPayOrderDTO> =>
  apiService.get(`/customer_api/v2/orders/${id}`);

export const attachPromocode = (
  order_id: number,
  promocode_id: string
): Promise<IOrderDTO | IScheduleAndPayOrderDTO> =>
  apiService.post(`/customer_api/v2/orders/${order_id}/attach_promocode`, {
    data: { promocode_id },
  });

export const detachPromocode = (
  order_id: number
): Promise<IOrderDTO | IScheduleAndPayOrderDTO> =>
  apiService.post(`/customer_api/v2/orders/${order_id}/detach_promocode`);

export const getOrdersWithIncompletePaymentPlans = (): Promise<
  IScheduleAndPayOrderWithPaymentPLanDTO[]
> => apiService.get(`/customer_api/v2/orders/with_incomplete_payment_plans`);

export const getAvailablePaymentPlans = (
  order_id: number
): Promise<IAvailableOrderPaymentPlan[]> =>
  apiService.get(`/customer_api/v2/orders/${order_id}/available_payment_plans`);

export const attachPaymentPlan = (
  order_id: number,
  payment_plan_id: string
): Promise<IScheduleAndPayOrderDTO> =>
  apiService.post(`/customer_api/v2/orders/${order_id}/attach_payment_plan`, {
    data: { payment_plan_id },
  });

export const detachPaymentPlan = (
  order_id: number
): Promise<IScheduleAndPayOrderDTO> =>
  apiService.post(`/customer_api/v2/orders/${order_id}/detach_payment_plan`);

export const payInstallment = (
  order_id: number,
  installment_id: string
): Promise<IScheduleAndPayOrderWithPaymentPlanDTO> =>
  apiService.post(
    `/customer_api/v2/orders/${order_id}/installments/${installment_id}/pay`
  );

export const registerCustomerCheckoutVisit = (payload: {
  funbox_id: string;
  location_id: number;
  student_ids: number[];
}) =>
  apiService.post("/customer_api/v2/orders/register_customer_checkout_visit", {
    data: payload,
  });
