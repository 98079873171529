import "@sizdevteam1/funjoiner-uikit/icons/index.scss";
import "@sizdevteam1/funjoiner-uikit/styles/typography.scss";
import "@sizdevteam1/funjoiner-uikit/styles/theme.scss";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { storesContext } from "./hooks/useStores";
import { RootStore } from "./stores";
import { configure } from "mobx";
import { COMMIT_TIMESTAMP, SENTRY_DSN, SENTRY_ENV, VERSION } from "./config";
import setupSentry from "./setup-sentry";

dayjs.extend(advancedFormat);

// loadLuckyOrangeScript();
configure({
  enforceActions: "never",
});

console.log(`Commit timestamp: ${COMMIT_TIMESTAMP}
Version: ${VERSION}
`);

if (SENTRY_DSN) {
  setupSentry(SENTRY_DSN, SENTRY_ENV || "UNKNOWN");
}

const rootStore = new RootStore();

const app = (
  <storesContext.Provider value={rootStore}>
    <App />
  </storesContext.Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
