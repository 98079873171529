import { createBrowserHistory, History, Location } from "history";
import { action, computed, makeObservable, observable } from "mobx";
import { PageConfig } from "src/util/typedRouting";
import { afterScheduleQuestionSetsRoute } from "../pages/AfterScheduleQuestionSetsPage/afterScheduleQuestionSetsRoute";
import { studentQuestionSetRoute } from "../pages/SmartFormsPage/StudentQuestionSetPage/studentQuestionSetRoute";
import { cancelCreditRoute } from "../pages/SelfRechedulePage/cancelCreditRoute";
import { invoiceSentSuccessRoute } from "../pages/SuccessPages/invoiceSentSuccessRoute";
import { rescheduleRoute } from "../pages/SelfRechedulePage/rescheduleRoute";
import { beforeScheduleQuestionsPageRoute } from "../pages/BeforeSheduleQuestionsPage/beforeScheduleQuestionsPageRoute";
import { scheduleSharingRoute } from "../pages/SuccessPages/scheduleSharingRoute";
import { scheduleAndPaySuccessRoute } from "../pages/SuccessPages/scheduleAndPaySuccessRoute";
import { scheduleSuccessRoute } from "../pages/SuccessPages/scheduleSuccessRoute";
import { completeCustomerProfilePageRoute } from "../pages/Schedule/CompleteCustomerProfilePage/completeCustomerProfilePageRoute";
import { providePaymentInformationPageRoute } from "../pages/Schedule/providePaymentInformationPageRoute";
import { joinWaitlistRoute } from "../pages/Schedule/JoinWaitlistPage/joinWaitlistPageRoute";
import { StrictOmit } from "@sizdevteam1/funjoiner-uikit/types";

export const ROUTES = {
  ROOT: "/",
  WELCOME_SCREEN: "/welcome",
  SELECT_FUNBOX_PAGE: "/select-funbox",
  SELECT_FUNBOX_PAGE_BY_ID_ACTION: "/select-funbox/:id",
  SELECT_FUNBOX_PAGE_BY_URL_PART_ACTION: "/funbox/:url_part",
  BOOKING: "/booking",
  PAYMENTS_HISTORY: "/payments_history",
  NEW_CARD: "/new_card",
  CARDS: "/cards",
  PRODUCT: "/product",
  PAYMENT: "/payment",
  PAYMENT_SUCCESS: "/payment_success",
  SCHEDULE_AND_PAY_SUCCESS: scheduleAndPaySuccessRoute.path,
  APPLICATION_SUCCESS: "/application_success",
  SCHEDULE: "/schedule",
  THANKYOU: scheduleSuccessRoute.path,
  SCHEDULE_ADD_PARTICIPANT: "/schedule/add-participant",
  SCHEDULE_WAITLIST: joinWaitlistRoute.path,
  SCHEDULE_CHECKOUT: "/schedule/checkout&pay",
  SCHEDULE_QUESTIONS_BEFORE: beforeScheduleQuestionsPageRoute.path,
  SCHEDULE_COMPLETE_CUSTOMER_PROFILE: completeCustomerProfilePageRoute.path,
  SCHEDULE_PROVIDE_PAYMENT_INFORMATION: providePaymentInformationPageRoute.path,
  RESCHEDULE: rescheduleRoute.path,
  CANCEL_CREDIT: cancelCreditRoute.path,
  UPGRADE_CREDIT: "/upgrade_credit/:id",
  LIABILITY: "/liability",
  SIGN_IN: "/sign-in",
  ADD_PARTICIPANT: "/add-participant",
  EDIT_PARTICIPANT: "/edit-participant/:id",
  CHECKOUT: "/checkout",
  PAYMENT_PLAN: "/payment_plan/:id",
  DASHBOARD: "/dashboard",
  AVAILABILITY: "/availability",
  PROFILE: "/profile",
  PARTICIPANTS: "/participants",
  CONTACTS: "/contacts",
  PERSONAL_INFO: "/personal-info",
  HISTORY: "/history",
  DOCUMENTS: "/documents",
  SMART_FORMS: "/smart-forms",
  PROMOCODES: "/promocodes",
  DOCUMENT_SIGN_SUCCESS_CALLBACK: "/documents/sign_success/:id",
  LOCATIONS: "/locations",
  DIRECTIONS_DETAILS: "/directions",
  HELP: "/support",
  CUSTOM_DOCUMENT: "/custom-documents/:id",
  STUDENT_QUESTION_SETS: studentQuestionSetRoute.path,
  AFTER_SCHEDULE_QUESTION_SETS: afterScheduleQuestionSetsRoute.path,
  SCHEDULE_SHARING: scheduleSharingRoute.path,
  INVOICE_SENT_SUCCESS: invoiceSentSuccessRoute.path,
  FLEXIBLE_PAYMENTS: "/flexible-payments",
  EXPLORE_OFFERINGS: "/explore-offerings",
} as const;

type TPath = (typeof ROUTES)[keyof typeof ROUTES];

export const pathFromLocation = (location: Location) => {
  return location.pathname + location.search;
};

type SearchParams = Record<string, string>;
type NavigateOptions = {
  id?: string | number;
  replace?: boolean;
  rewriteFromOnReplace?: boolean;
  searchParams?: SearchParams;
  preserveOldSearch?: boolean;
  from?: string | "clear";
  state?: Record<string, any>;
};

export default class RouterStore {
  history: History<{ from?: string; fromState?: object } | undefined>;

  constructor(history?: History<{ from?: string }>) {
    this.history = history ?? createBrowserHistory();
    makeObservable(this);

    this.history.listen((location) => {
      this.syncLocation(location);
    });

    this.syncLocation(this.history.location);
  }

  @observable
  currentPath: string = ROUTES.ROOT;

  @observable
  currentHash = "";

  @observable
  searchParams: Record<string, string> = {};

  get historyState() {
    return this.history.location.state;
  }
  resetState() {
    this.history.replace({ state: undefined });
  }

  @action.bound
  returnToSourcePage(fallback?: TPath, forwardState: boolean = false) {
    let from = this.historyState?.from ?? fallback;
    if (from === this.currentPath) from = fallback;
    if (from == null) {
      this.history.goBack();
    } else {
      const fromState = this.historyState?.fromState;
      let state = forwardState
        ? {
            ...fromState,
            ...this.historyState,
          }
        : { ...fromState };
      this.navigate(from as TPath, {
        replace: true,
        state: state,
      });
    }
  }

  @action
  navigate = (route: `${TPath}${string}`, options?: NavigateOptions) => {
    console.log(this.historyState, route);
    return this._navigate(route, options);
  };

  @action.bound
  navigateToRoute(
    routeConfig: PageConfig,
    options?: StrictOmit<NavigateOptions, "searchParams" | "state" | "id">
  ) {
    const { path, ...other } = routeConfig;
    return this._navigate(path, {
      ...options,
      searchParams: other.searchParams,
      state: other.state,
    });
  }

  @action
  private _navigate = (route: string, options?: NavigateOptions) => {
    let _route: string = route;
    const from = this._detectFromPage(options);

    if (options?.id != null)
      _route = _route.replace(":id", options.id.toString());

    const pathObj = this._mergeSearchParams(
      _route,
      options?.searchParams,
      options?.preserveOldSearch
    );

    if (options?.state != null && "from" in options.state) {
      console.error(
        `Route's state contains \`from\` property, 
        which is reserved for navigation purposes`
      );
    }

    const state = {
      ...options?.state,
      fromState: undefined as object | undefined,
      from: undefined as string | undefined,
    };

    if (from != null) {
      state.from = from;
      state.fromState = this.historyState;
    }
    if (options?.replace) {
      this.history.replace(pathObj, state);
    } else {
      this.history.push(pathObj, state);
    }
  };

  @action
  setSearchParam = (key: string, value?: string | number, replace = false) => {
    let newSearch = new URLSearchParams(this.currentSearchString);
    if (value == null) {
      newSearch.delete(key);
    } else {
      newSearch.set(key, String(value));
    }
    if (newSearch.toString() !== this.currentSearchString) {
      const pathObj = {
        pathname: this.currentPath,
        search: newSearch.toString(),
      };

      if (replace) {
        this.history.replace(pathObj, this.historyState);
      } else {
        this.history.push(pathObj, this.historyState);
      }
    }
  };

  @action
  private syncLocation = (location: Location) => {
    this.currentPath = location.pathname;
    this.currentHash = location.hash;
    this.syncSearchParams(
      decodeURIComponent(location.search.replace(/\+/g, " "))
    );
  };

  @action
  private syncSearchParams = (searchString: string) => {
    let newSearchParams = Object.fromEntries(new URLSearchParams(searchString));
    const newKeys = Object.keys(newSearchParams);
    const oldKeys = Object.keys(this.searchParams);
    new Set([...newKeys, ...oldKeys]).forEach((k) => {
      const newValue = newSearchParams[k];
      if (this.searchParams[k] !== newValue) {
        if (newValue == null) {
          delete this.searchParams[k];
        } else {
          this.searchParams[k] = newValue;
        }
      }
    });
  };

  @computed
  get currentSearch() {
    return new URLSearchParams(this.searchParams);
  }

  @computed
  private get currentSearchString() {
    return this.currentSearch.toString();
  }

  private _detectFromPage(
    options: NavigateOptions | undefined
  ): string | undefined {
    if (options?.from === "clear") {
      return undefined;
    }
    if (options?.from != null) {
      return options.from;
    }
    return options?.replace === true && options?.rewriteFromOnReplace !== true
      ? this.historyState?.from
      : pathFromLocation(this.history.location);
  }

  private _mergeSearchParams = (
    route: string,
    searchParams: SearchParams | undefined,
    preserveOldSearch = false
  ): {
    pathname: string;
    search: string;
  } => {
    const [pathname, routeQueryParams] = route.split("?");
    let searchParamsToUse = new URLSearchParams(
      preserveOldSearch ? this.currentSearch : {}
    );
    const routeSearchParams = new URLSearchParams(routeQueryParams);

    for (const [key, value] of routeSearchParams.entries()) {
      searchParamsToUse.set(key, value);
    }
    const newSearchParams = searchParams ?? {};

    for (const key of Object.keys(newSearchParams)) {
      const value = newSearchParams[key];
      searchParamsToUse.set(key, value);
    }

    return {
      pathname: pathname,
      search: searchParamsToUse.toString(),
    };
  };
}
