import React, { Suspense } from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { Splash } from "@sizdevteam1/funjoiner-uikit";
import useStores from "./hooks/useStores";
import { Observer } from "mobx-react-lite";
import AppHeader from "./pages/AppHeader";
import Footer from "./components/Footer";
import { ROUTES } from "./stores/RouterStore";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";

import CommunicationPreferencesModal from "./components/CommunicationPreferencesModal";

import { applicationProcessPageRoute } from "./pages/ApplicationProcessPage/applicationProcessPageRoute";
import { afterScheduleQuestionSetsRoute } from "./pages/AfterScheduleQuestionSetsPage/afterScheduleQuestionSetsRoute";
import { studentQuestionSetRoute } from "./pages/SmartFormsPage/StudentQuestionSetPage/studentQuestionSetRoute";
import { cancelCreditRoute } from "./pages/SelfRechedulePage/cancelCreditRoute";
import { rescheduleRoute } from "./pages/SelfRechedulePage/rescheduleRoute";
import { applicationsAndWaitlistsPageRoute } from "./pages/ApplicationsAndWaitlistsPage/applicationsAndWaitlistsRoute";
import { applicationPageRoute } from "./pages/ApplicationPage/applicationPageRoute";
import { smartFormPipelinePageRoute } from "./pages/SmartFormPipelinePage/smartFormPipelinePageRoute";
const CheckoutAndPaymentPage = React.lazy(
  () => import("./pages/Checkout&Payment/Checkout&PaymentPage")
);

const SmartFormPipelinePage = React.lazy(
  () => import("./pages/SmartFormPipelinePage/SmartFormPipelinePage")
);

const ApplicationPage = React.lazy(
  () => import("./pages/ApplicationPage/ApplicationPage")
);

const ApplicationsAndWaitlistsPage = React.lazy(
  () =>
    import("./pages/ApplicationsAndWaitlistsPage/ApplicationsAndWaitlistsPage")
);

const SelfReschedulePage = React.lazy(
  () => import("./pages/SelfRechedulePage/SelfReschedulePage")
);
const CancelCreditPage = React.lazy(
  () => import("./pages/SelfRechedulePage/CancelCreditPage")
);
const StudentQuestionSetPage = React.lazy(
  () =>
    import(
      "./pages/SmartFormsPage/StudentQuestionSetPage/StudentQuestionSetPage"
    )
);
const AfterScheduleQuestionSetsPage = React.lazy(
  () =>
    import(
      "./pages/AfterScheduleQuestionSetsPage/AfterScheduleQuestionSetsPage"
    )
);
const ScheduleSharingPage = React.lazy(
  () => import("./pages/SuccessPages/ScheduleSharingPage")
);
const InvoiceSentSuccessPage = React.lazy(
  () => import("./pages/SuccessPages/InvoiceSentSuccessPage")
);
const PersonalInfoPage = React.lazy(
  () => import("./pages/ProfilePage/PersonalInfoPage/PersonalInfoPage")
);
const ProfilePage = React.lazy(
  () => import("./pages/ProfilePage/ProfilePage/ProfilePage")
);
const OrderPaymentPlanPage = React.lazy(
  () => import("./pages/OrderPaymentPlanPage/OrderPaymentPlanPage")
);
const WelcomeScreenPage = React.lazy(
  () => import("./pages/WelcomeScreenPage/WelcomeScreenPage")
);
const CreditsAndPackagesPage = React.lazy(
  () => import("./pages/CreditsAndPackagesPage/CreditsAndPackagesPage")
);
const ExploreOfferingsPage = React.lazy(
  () => import("./pages/ExploreOfferingsPage/ExploreOfferingsPage")
);
const PreselectFunboxByIdOrUrlPartPage = React.lazy(
  () =>
    import(
      "./pages/PreselectFunboxByIdOrUrlPartPage/PreselectFunboxByIdOrUrlPartPage"
    )
);
const ParticipantsPage = React.lazy(
  () => import("./pages/ParticipantsPage/ParticipantsPage")
);
const ContactsPage = React.lazy(
  () => import("./pages/ContactsPage/ContactsPage")
);

const ApplicationSuccessPage = React.lazy(
  () => import("./pages/SuccessPages/ApplicationSuccessPage")
);
const SelectFunboxPage = React.lazy(() => import("./pages/SelectFunboxPage"));
const SignInPage = React.lazy(() => import("./pages/SignInPage"));
const ThankYouPage = React.lazy(
  () => import("./pages/SuccessPages/ScheduleSuccessPage")
);
const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));
const AvailabilityPage = React.lazy(() => import("./pages/AvailabilityPage"));
const HistoryPage = React.lazy(() => import("./pages/HistoryPage"));
const CardsPage = React.lazy(() => import("./pages/CardsPage"));
const DocumentsPage = React.lazy(() => import("./pages/DocumentsPage"));
const PaymentSuccessPage = React.lazy(
  () => import("./pages/SuccessPages/PaymentSuccessPage")
);
const SchedulePage = React.lazy(() => import("./pages/Schedule/SchedulePage"));
const HelpPage = React.lazy(() => import("./pages/HelpPage"));
const DirectionsPage = React.lazy(() => import("./pages/DirectionsPage"));
const ScheduleAndPaySuccessPage = React.lazy(
  () => import("./pages/SuccessPages/ScheduleAndPaySuccessPage")
);
const UpgradeCreditPage = React.lazy(
  () => import("./pages/UpgradeCredit/UpgradeCreditPage")
);
const PromocodesPage = React.lazy(
  () => import("./pages/PromocodesPage/PromocodesPage")
);
const CustomDocumentPage = React.lazy(
  () => import("./pages/DocumentsPage/CustomDocumentPage/CustomDocumentPage")
);
const ParticipantCreateOrEditPage = React.lazy(
  () => import("./pages/ParticipantCreateOrEditPage")
);
const SmartFormsPage = React.lazy(
  () => import("./pages/SmartFormsPage/SmartFormsPage")
);
const ApplicationProcessPage = React.lazy(
  () => import("./pages/ApplicationProcessPage/ApplicationProcessPage")
);

const App: React.FC = () => {
  const rootStore = useStores();
  const { paymentStore, routerStore, funboxStore } = rootStore;

  return (
    <div className="App">
      <Router history={routerStore.history}>
        <Observer>
          {() =>
            rootStore.showAppLoading ? (
              <Splash pathToAnimation={"/splash.json"} />
            ) : (
              <Suspense fallback={<Splash pathToAnimation={"/splash.json"} />}>
                <Elements stripe={paymentStore.stripePromise}>
                  <CommunicationPreferencesModal />
                  <main>
                    <AppHeader />
                    <Switch>
                      <PublicRoute
                        title={"Lineup"}
                        path={[
                          ROUTES.SELECT_FUNBOX_PAGE_BY_URL_PART_ACTION,
                          ROUTES.SELECT_FUNBOX_PAGE_BY_ID_ACTION,
                        ]}
                        component={PreselectFunboxByIdOrUrlPartPage}
                      />
                      <PublicRoute
                        title={"Lineup"}
                        path={ROUTES.SELECT_FUNBOX_PAGE}
                        exact
                        component={SelectFunboxPage}
                      />
                      <PublicRoute
                        title={"Sign In"}
                        path={ROUTES.SIGN_IN}
                        component={SignInPage}
                      />
                      <PublicRoute
                        title={"Add Participant Page"}
                        path={ROUTES.ADD_PARTICIPANT}
                        component={ParticipantCreateOrEditPage}
                      />
                      <PublicRoute
                        title={"Edit Participant Page"}
                        path={ROUTES.EDIT_PARTICIPANT}
                        component={ParticipantCreateOrEditPage}
                      />

                      <PublicRoute
                        title={"Get Help"}
                        path={ROUTES.HELP}
                        component={HelpPage}
                      />

                      <PrivateRoute
                        title={"My Dashboard"}
                        path={ROUTES.DASHBOARD}
                        component={DashboardPage}
                      />

                      <PrivateRoute
                        title={"Profile"}
                        path={ROUTES.PROFILE}
                        component={ProfilePage}
                      />
                      <PrivateRoute
                        title={"Contacts Page"}
                        path={ROUTES.CONTACTS}
                        component={ContactsPage}
                      />
                      <PrivateRoute
                        title={"Transaction History"}
                        path={ROUTES.HISTORY}
                        component={HistoryPage}
                      />
                      <PrivateRoute
                        title={"Cards"}
                        path={ROUTES.CARDS}
                        component={CardsPage}
                      />
                      <PrivateRoute
                        title={"Documents"}
                        path={[
                          ROUTES.DOCUMENTS,
                          ROUTES.DOCUMENT_SIGN_SUCCESS_CALLBACK,
                        ]}
                        component={DocumentsPage}
                      />
                      <PrivateRoute
                        title={"Participants"}
                        path={[ROUTES.PARTICIPANTS]}
                        component={ParticipantsPage}
                      />
                      <PrivateRoute
                        title={"Personal Information"}
                        path={[ROUTES.PERSONAL_INFO]}
                        component={PersonalInfoPage}
                      />
                      <PrivateRoute
                        title={"Promo Codes"}
                        path={ROUTES.PROMOCODES}
                        component={PromocodesPage}
                      />
                      <PrivateRoute
                        title={"Submit Document"}
                        path={ROUTES.CUSTOM_DOCUMENT}
                        component={CustomDocumentPage}
                      />
                      <PrivateRoute
                        title="Personal Information"
                        path={studentQuestionSetRoute.path}
                        component={StudentQuestionSetPage}
                      />
                      <PrivateRoute
                        title="Personal Information"
                        exact
                        path={ROUTES.SMART_FORMS}
                        component={SmartFormsPage}
                      />
                      <PrivateRoute
                        title="Personal Information"
                        path={afterScheduleQuestionSetsRoute.path}
                        component={AfterScheduleQuestionSetsPage}
                      />

                      <PrivateRoute
                        title={"Application"}
                        path={applicationPageRoute.path}
                        component={ApplicationPage}
                      />
                      <PrivateRoute
                        title={"Applications/Waitlists"}
                        path={applicationsAndWaitlistsPageRoute.path}
                        component={ApplicationsAndWaitlistsPage}
                      />

                      <PrivateRoute
                        title={"Schedule Dates"}
                        path={ROUTES.SCHEDULE}
                        component={SchedulePage}
                      />
                      <PrivateRoute
                        title={"Application Process"}
                        path={applicationProcessPageRoute.path}
                        component={ApplicationProcessPage}
                      />
                      <PrivateRoute
                        title={"Smart Forms"}
                        path={smartFormPipelinePageRoute.path}
                        component={SmartFormPipelinePage}
                      />
                      <PrivateRoute
                        title={"Payment Successful!"}
                        path={ROUTES.PAYMENT_SUCCESS}
                        component={PaymentSuccessPage}
                      />
                      <PrivateRoute
                        title={"Application Successful!"}
                        path={ROUTES.APPLICATION_SUCCESS}
                        component={ApplicationSuccessPage}
                      />
                      <PrivateRoute
                        title={"Self Reschedule"}
                        path={rescheduleRoute.path}
                        component={SelfReschedulePage}
                      />
                      <PrivateRoute
                        title={"Cancel Reservation"}
                        path={cancelCreditRoute.path}
                        component={CancelCreditPage}
                      />
                      <PrivateRoute
                        title={"Self Upgrade"}
                        path={ROUTES.UPGRADE_CREDIT}
                        component={UpgradeCreditPage}
                      />
                      <PrivateRoute
                        title={"Payment Plan"}
                        path={ROUTES.PAYMENT_PLAN}
                        component={OrderPaymentPlanPage}
                      />
                      <PublicRoute
                        title="Welcome"
                        component={WelcomeScreenPage}
                        path={ROUTES.WELCOME_SCREEN}
                        exact
                      />
                      {funboxStore.isBuyCreditsAndSaveEnabled && (
                        <PublicRoute
                          title="Credits & Packages"
                          component={CreditsAndPackagesPage}
                          path={ROUTES.FLEXIBLE_PAYMENTS}
                        />
                      )}
                      <PublicRoute
                        title="Explore Offerings Page"
                        component={ExploreOfferingsPage}
                        path={ROUTES.EXPLORE_OFFERINGS}
                      />
                      <PublicRoute
                        title={"Check Schedule"}
                        path={ROUTES.AVAILABILITY}
                        component={AvailabilityPage}
                      />
                      <PrivateRoute
                        title={"Directions and details"}
                        path={ROUTES.DIRECTIONS_DETAILS}
                        component={DirectionsPage}
                      />
                      <PrivateRoute
                        title="Schedule Sharing"
                        path={ROUTES.SCHEDULE_SHARING}
                        component={ScheduleSharingPage}
                      />
                      <PrivateRoute
                        title={"Checkout"}
                        path={ROUTES.CHECKOUT}
                        component={CheckoutAndPaymentPage}
                      />
                      <PrivateRoute
                        title={"Thank You!"}
                        path={ROUTES.THANKYOU}
                        component={ThankYouPage}
                      />
                      <PrivateRoute
                        title={"Thank You!"}
                        path={ROUTES.SCHEDULE_AND_PAY_SUCCESS}
                        component={ScheduleAndPaySuccessPage}
                      />
                      <PrivateRoute
                        title={"Invoice Sent!"}
                        path={ROUTES.INVOICE_SENT_SUCCESS}
                        component={InvoiceSentSuccessPage}
                      />
                      <Redirect
                        from={ROUTES.LOCATIONS}
                        to={ROUTES.SELECT_FUNBOX_PAGE}
                      />
                      <Redirect from={ROUTES.ROOT} to={ROUTES.WELCOME_SCREEN} />
                    </Switch>
                    <Footer />
                  </main>
                </Elements>
              </Suspense>
            )
          }
        </Observer>
      </Router>
    </div>
  );
};

export default App;
