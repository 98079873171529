import { Redirect, RouteProps } from "react-router-dom";
import PublicRoute from "src/components/PublicRoute";
import useStores from "../hooks/useStores";
import React, { Suspense } from "react";
import { pathFromLocation, ROUTES } from "../stores/RouterStore";
import { Loader, Splash } from "@sizdevteam1/funjoiner-uikit";
import { Observer } from "mobx-react-lite";

interface IProps extends RouteProps {
  title?: string;
}

const PrivateRoute: React.FC<IProps> = ({ component, children, ...rest }) => {
  const { authStore, routerStore } = useStores();

  const customerEmail = routerStore.currentSearch.get("customer_email");

  return (
    <PublicRoute
      {...rest}
      render={(data) => (
        <Observer>
          {() => {
            return !authStore.initialized ? (
              <Splash pathToAnimation={"/splash.json"} />
            ) : authStore.loggedIn ? (
              component ? (
                React.createElement(component, data)
              ) : null
            ) : (
              <Redirect
                to={{
                  pathname: ROUTES.SIGN_IN,
                  state: {
                    fromState: data.location.state,
                    from: pathFromLocation(data.location),
                  },
                  search:
                    data.location.search +
                    (customerEmail ? "&customer_email=" + customerEmail : ""),
                }}
              />
            );
          }}
        </Observer>
      )}
    />
  );
};
export default PrivateRoute;
